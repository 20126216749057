
import { defineComponent, onMounted, PropType, reactive } from "vue";
import ModalComponent from "@/components/modals/ModalComponent.vue";
import { AddressInterface } from "@/modules/addresses/interfaces/Address.interface";
import ApiService from "@/core/services/ApiService";
import Notify from "@/modules/common/helpers/Notify";

interface CartItem {
  image_url: string;
  name: string;
  variation_id: number;
  reference: string;
  size: string;
  price: number;
  quantity: number;
}

interface ComponentData {
  items: CartItem[] | null;
}

export default defineComponent({
  name: "CartViewModal",
  components: {
    ModalComponent,
  },

  props: {
    cart: {
      type: Object as PropType<AddressInterface>,
      required: true,
    },
  },

  setup(props) {
    const state = reactive<ComponentData>({
      items: null,
    });

    const loadCartItems = (): void => {
      ApiService.get(`cart/` + props.cart.id)
        .then(({ data }) => {
          state.items = data.items;
        })
        .catch((error) => Notify.apiError(error))
        .finally();
    };
    onMounted(() => {
      loadCartItems();
    });

    return {
      state,
      props,
      loadCartItems,
    };
  },
});
