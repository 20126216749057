<template>
  <card-component>
    <template #header>
      <i class="fas fa-shopping-cart card-header-icon"></i>
      Carts
      <span class="badge badge-secondary m-2">{{ carts.length }}</span>
    </template>
    <template #default>
      <div v-if="carts != null">
        <div id="carts_container" class="card_father scroll">
          <table class="table table-striped">
            <thead>
              <tr
                class="fw-bold fs-6 text-gray-800 border-bottom border-gray-200"
              >
                <th>ID</th>
                <th>Shipping</th>
                <th>Total items</th>
                <th>Total €</th>
                <th>Created</th>
                <th>Updated</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="cart in carts" :key="cart.id" class="cart">
                <td>{{ cart.id }}</td>
                <td>{{ cart.carrier }}</td>
                <td>{{ cart.total_items }}</td>
                <td>{{ cart.total_sum }}</td>
                <td>{{ cart.created_at }}</td>
                <td>{{ cart.updated_at }}</td>
                <td>
                  <button
                    type="button"
                    class="btn btn-sm"
                    data-bs-toggle="modal"
                    data-bs-target="#cart_view_modal"
                    style="margin-right: 1rem; padding: 0.25rem 0.5rem"
                    @click="showCart(cart)"
                  >
                    <i
                      class="fas text-primary fa-eye"
                      style="padding-right: 0"
                    ></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <CartViewModal
        v-if="state.cartView != null"
        :cart="state.cartView"
        @hide="() => showCart(null)"
      />
    </template>
  </card-component>
</template>

<script lang="ts">
import { defineComponent, PropType, reactive } from "vue";
import CardComponent from "@/components/card/CardComponent.vue";
import CartViewModal from "@/modules/carts/widgets/CartViewModal.vue";

interface Cart {
  id?: string;
  carrier?: string;
  total_items?: string;
  total_sum?: string;
  created_at?: string;
  updated_at?: string;
}

interface ComponentData {
  cartView;
}

export default defineComponent({
  name: "CartsCard",
  components: { CardComponent, CartViewModal },
  props: {
    carts: {
      type: Array as PropType<Cart>,
      required: true,
    },
  },

  setup(props) {
    const state = reactive<ComponentData>({
      cartView: null,
    });

    const showCart = (cart): void => {
      state.cartView = cart;
    };

    return {
      state,
      props,
      showCart,
    };
  },
});
</script>

<style scoped></style>
