
import { defineComponent, PropType, reactive } from "vue";
import CardComponent from "@/components/card/CardComponent.vue";
import CartViewModal from "@/modules/carts/widgets/CartViewModal.vue";

interface Cart {
  id?: string;
  carrier?: string;
  total_items?: string;
  total_sum?: string;
  created_at?: string;
  updated_at?: string;
}

interface ComponentData {
  cartView;
}

export default defineComponent({
  name: "CartsCard",
  components: { CardComponent, CartViewModal },
  props: {
    carts: {
      type: Array as PropType<Cart>,
      required: true,
    },
  },

  setup(props) {
    const state = reactive<ComponentData>({
      cartView: null,
    });

    const showCart = (cart): void => {
      state.cartView = cart;
    };

    return {
      state,
      props,
      showCart,
    };
  },
});
