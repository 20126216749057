<template>
  <card-component>
    <template #header>
      <i class="fas fa-route card-header-icon"></i>
      Traces
      <span class="badge badge-secondary m-2">{{ traces.length }}</span>
    </template>
    <template #default>
      <div v-if="traces != null">
        <div id="traces_container" class="card_father scroll">
          <table class="table table-striped">
            <thead>
              <tr
                class="fw-bold fs-6 text-gray-800 border-bottom border-gray-200"
              >
                <th>Action</th>
                <th>Category</th>
                <th>Created_at</th>
                <th>Label</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="trace in traces" :key="trace.id" class="email_letter">
                <td>{{ trace.action }}</td>
                <td>{{ trace.name }}</td>
                <td>{{ trace.created_at }}</td>
                <td>{{ trace.label }}</td>
                <td>{{ trace.value }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </template>
  </card-component>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import CardComponent from "@/components/card/CardComponent.vue";

export default defineComponent({
  name: "TracesList",
  components: { CardComponent },
  props: {
    traces: {
      type: Array,
      required: true,
    },
  },
});
</script>

<style scoped></style>
