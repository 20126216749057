
import ApiService from "@/core/services/ApiService";
import { defineComponent } from "vue";
import CardComponent from "@/components/card/CardComponent.vue";
import LoadingIndicator from "@/components/spinners/LoadingIndicator.vue";
import RequestFailedError from "@/components/errors/RequestFailedError.vue";

interface ComponentData {
  loading: boolean;
  error?: unknown;
  emails?: unknown;
}

export default defineComponent({
  name: "EmailList",
  components: { RequestFailedError, LoadingIndicator, CardComponent },
  props: {
    email: {
      type: String,
      required: true,
    },
  },

  data(): ComponentData {
    return {
      loading: true,
      error: undefined,
      emails: {},
    };
  },

  watch: {
    email() {
      this.getEmails();
    },
  },

  mounted() {
    this.getEmails();
  },

  methods: {
    getEmails() {
      this.resetState();
      ApiService.get(`customer/${this.$route.params.id}/emails`)
        .then(({ data }) => {
          this.emails = data.data;
        })
        .catch((error) => {
          this.error = error;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    resetState(): void {
      this.loading = true;
      this.error = undefined;
      this.emails = {};
    },
  },
});
