
import { defineComponent, PropType, ref } from "vue";
import ApiService from "@/core/services/ApiService";
import { ElMessage } from "element-plus";
import Notify from "@/modules/common/helpers/Notify";
import { AddressInterface } from "@/modules/addresses/interfaces/Address.interface";
import ModalComponent from "@/components/modals/ModalComponent.vue";

export default defineComponent({
  name: "AddressDeleteModal",

  components: {
    ModalComponent,
  },

  props: {
    address: {
      type: Object as PropType<AddressInterface>,
      required: true,
    },
  },

  emits: ["deleted", "hide"],
  setup(props, { emit }) {
    const modal = ref<typeof ModalComponent | null>(null);

    const deleteAddress = () => {
      ApiService.delete(`address/` + props.address.id)
        .then(() => {
          ElMessage.success("Address deleted.");
          modal.value?.hide();
          emit("deleted");
        })
        .catch((error) => Notify.apiError(error));
    };

    return {
      props,
      deleteAddress,
      modal,
    };
  },
});
