
import { defineComponent, onMounted, reactive, ref } from "vue";
import ApiService from "@/core/services/ApiService";
import { ElMessage } from "element-plus";
import Notify from "@/modules/common/helpers/Notify";
import FormSubmitButton from "@/components/forms/buttons/FormSubmitButton.vue";
import { ErrorMessage, Field, useForm } from "vee-validate";
import ModalComponent from "@/components/modals/ModalComponent.vue";
import Multiselect from "@vueform/multiselect";
import { useRoute } from "vue-router";
import { setOptionsValues } from "@/components/multiselect-options/Options";
import { MultiselectOptions } from "@/components/multiselect-options/MultiselectOptionsInterface";

interface ComponentData {
  submitButtonLoading: boolean;
  countries: MultiselectOptions[] | null;
  states: MultiselectOptions[] | null;
  selectedCountry: number | null;
  selectedState: number | null;
}

export default defineComponent({
  name: "AddressCreateModal",
  components: {
    FormSubmitButton,
    Field,
    ErrorMessage,
    ModalComponent,
    Multiselect,
  },

  emits: ["created", "hide"],
  setup(props, { emit }) {
    const route = useRoute();
    const state = reactive<ComponentData>({
      submitButtonLoading: false,
      countries: null,
      states: null,
      selectedCountry: null,
      selectedState: null,
    });
    const modal = ref<typeof ModalComponent | null>(null);

    const { handleSubmit, setFieldError } = useForm();

    const createAddress = handleSubmit((values) => {
      const requestBody = {
        ...values,
        customer_id: route.params.id,
        country_id: state.selectedCountry,
        state_id: state.selectedState,
      };

      state.submitButtonLoading = true;
      setFieldError("country", undefined);
      ApiService.post(`address`, requestBody)
        .then(() => {
          state.submitButtonLoading = false;
          ElMessage.success("Address created.");
          modal.value?.hide();
          emit("created");
        })
        .catch((error) => {
          Notify.apiError(error, setFieldError);
        })
        .finally(() => {
          state.submitButtonLoading = false;
        });
    });

    const loadData = (countryId: number) =>
      new Promise((resolve, reject) => {
        ApiService.get(`address/countries`)
          .then(({ data }) => {
            state.countries = setOptionsValues(data.countries);

            ApiService.get(`address/countries/` + countryId + `/states`)
              .then(({ data }) => {
                if (data.states.length > 0) {
                  state.states = setOptionsValues(data.states);
                } else {
                  state.states = null;
                  state.selectedState = null;
                }
                return resolve(true);
              })
              .catch(reject);
          })
          .catch(reject);
      });

    const countryChanged = (value) => {
      loadData(value as number).then(() => {
        if (state.states != null) {
          state.selectedState = state.states[0].value;
        }
      });
    };

    const getCountries = (): void => {
      ApiService.get(`address/countries`)
        .then(({ data }) => {
          state.countries = setOptionsValues(data.countries);
          state.selectedCountry = state.countries[0]["id"];
          getStates();
        })
        .catch((error) => Notify.apiError(error));
    };

    const getStates = (): void => {
      if (state.selectedCountry !== undefined) {
        ApiService.get(`address/countries/` + state.selectedCountry + `/states`)
          .then(({ data }) => {
            state.states = data["states"];
            if (data["states"].length !== 0) {
              state.selectedState = data["states"][0]["id"];
            }
          })
          .catch((error) => Notify.apiError(error));
      }
    };

    onMounted(() => {
      getCountries();
    });

    return {
      props,
      state,
      createAddress,
      countryChanged,
      modal,
    };
  },
});
