<template>
  <div id="delete_modal" class="modal fade" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Deleting customer</h5>
          <!--begin::Close-->
          <div
            class="btn btn-icon btn-sm btn-active-light-primary ms-2"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span class="svg-icon svg-icon-2x"></span>
          </div>
          <!--end::Close-->
        </div>

        <div class="modal-body">
          <p>
            Are you sure you want to delete the customer
            {{ customer.first_name }} {{ customer.last_name }} ?
          </p>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-light" data-bs-dismiss="modal">
            Cancel
          </button>
          <button type="button" class="btn btn-danger" @click="deleteCustomer">
            Delete customer
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ApiService from "@/core/services/ApiService";
import Notify from "@/modules/common/helpers/Notify";
import { ElMessage } from "element-plus";

export default defineComponent({
  name: "CustomerDeleteModal",

  props: {
    customer: {
      type: Object,
      required: true,
    },
  },

  methods: {
    deleteCustomer: function () {
      ApiService.delete(`customer/${this.$route.params.id}`)
        .then(() => {
          ElMessage.success("Customer deleted.");
        })
        .catch((error) => Notify.apiError(error));
    },
  },
});
</script>

<style scoped></style>
