
import ApiService from "@/core/services/ApiService";
import EmailCard from "@/modules/customers/widgets/EmailCard.vue";
import TracesCard from "@/modules/customers/widgets/TracesCard.vue";
import AddressesCard from "@/modules/customers/widgets/AddressesCard.vue";
import CartsCard from "@/modules/customers/widgets/CartsCard.vue";
import { defineComponent, reactive } from "vue";
import PreviousOrdersWidget from "@/modules/common/widgets/PreviousOrders/PreviousOrdersWidget.vue";
import CustomerUpdateModal from "@/modules/customers/widgets/CustomerUpdateModal.vue";
import CustomerDeleteModal from "@/modules/customers/widgets/CustomerDeleteModal.vue";
import Notify from "@/modules/common/helpers/Notify";
import { CustomerResponse } from "@/modules/customers/interfaces/CustomerResponse.interface";
import { useRoute } from "vue-router";
import { setPageTitle } from "@/core/helpers/dom";

interface ComponentData {
  response?: CustomerResponse | null;
  submitButtonLoading: boolean;
  isEditCustomer: boolean;
}

export default defineComponent({
  name: "ResourceViewPage",
  components: {
    PreviousOrdersWidget,
    AddressesCard,
    EmailCard,
    TracesCard,
    CartsCard,
    CustomerUpdateModal,
    CustomerDeleteModal,
  },

  setup() {
    const route = useRoute();
    const state = reactive<ComponentData>({
      response: null,
      submitButtonLoading: false,
      isEditCustomer: false,
    });

    const customerUpdated = (): void => {
      getCustomer();
    };

    setPageTitle("Customer #" + String(route.params.id));

    const getCustomer = (): void => {
      ApiService.get(`customer/` + String(route.params.id))
        .then(({ data }: { data: CustomerResponse }) => {
          console.log(data);
          state.response = data;
        })
        .catch((error) => Notify.apiError(error))
        .finally();
    };
    getCustomer();

    return {
      state,
      customerUpdated,
      getCustomer,
    };
  },
});
