<template>
  <card-component v-if="state.addresses">
    <template #header>
      <i class="fas fa-map-marker-alt card-header-icon"></i>
      Addresses
      <span class="badge badge-secondary m-2">{{
        state.addresses.length
      }}</span>
    </template>
    <template #toolbar>
      <span
        v-if="
          roles.length > 0 &&
          (roles.includes('CUSTOMER_SERVICE') || roles.includes('ADMIN'))
        "
      >
        <button
          type="button"
          class="btn btn-sm"
          data-bs-toggle="modal"
          data-bs-target="#create_address_modal"
          style="margin-right: 0.5rem"
          @click="state.isCreateAddress = true"
        >
          <i class="fas fa-plus"></i> Create address
        </button>
      </span>
    </template>
    <template #default>
      <div v-if="state.addresses != null">
        <div id="addresses_container" class="card_father scroll">
          <table class="table table-striped">
            <thead>
              <tr
                class="fw-bold fs-6 text-gray-800 border-bottom border-gray-200"
              >
                <th>Firstname</th>
                <th>Lastname</th>
                <th>Country</th>
                <th>State</th>
                <th>City</th>
                <th>Address 1</th>
                <th>Address 2</th>
                <th>ZIP</th>
                <th>Phone</th>
                <th>Additional info</th>
                <th>Address type</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="address in state.addresses"
                :key="address.id"
                class="email_letter"
              >
                <td>{{ address.firstname }}</td>
                <td>{{ address.lastname }}</td>
                <td>{{ address.country.name }}</td>
                <td>{{ address.state.name }}</td>
                <td>{{ address.city }}</td>
                <td>{{ address.address1 }}</td>
                <td>{{ address.address2 }}</td>
                <td>{{ address.zip }}</td>
                <td>{{ address.phone }}</td>
                <td>{{ address.additional_information }}</td>
                <td>
                  <span
                    v-if="address.parent_address_id !== null"
                    class="badge badge-pill badge-success"
                  >
                    Label
                  </span>
                </td>
                <td v-if="roles.length > 0">
                  <button
                    type="button"
                    class="btn btn-sm"
                    data-bs-toggle="modal"
                    data-bs-target="#update_address_modal"
                    style="margin-right: 1.5rem; padding: 0.25rem 0"
                    @click="setEditAddress(address)"
                  >
                    <i
                      class="fas text-primary fa-pen"
                      style="padding-right: 0"
                    ></i>
                  </button>
                  <span
                    v-if="
                      roles.includes('CUSTOMER_SERVICE') ||
                      roles.includes('ADMIN')
                    "
                  >
                    <button
                      type="button"
                      class="btn btn-sm"
                      data-bs-toggle="modal"
                      data-bs-target="#delete_address_modal"
                      style="padding: 0.25rem 0"
                      @click="setDeleteAddress(address)"
                    >
                      <i
                        class="fas text-danger fa-times"
                        style="padding-right: 0"
                      ></i>
                    </button>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <AddressCreateModal
        v-if="state.isCreateAddress !== false"
        @hide="() => (state.isCreateAddress = false)"
        @created="reloadAddresses()"
      />

      <AddressUpdateModal
        v-if="state.editableAddress != null"
        :address="state.editableAddress"
        @hide="() => setEditAddress(null)"
        @updated="reloadAddresses()"
      />

      <AddressDeleteModal
        v-if="state.deletableAddress != null"
        :address="state.deletableAddress"
        @hide="() => setDeleteAddress(null)"
        @deleted="reloadAddresses()"
      />
    </template>
  </card-component>
</template>

<script lang="ts">
import { defineComponent, reactive, computed, onMounted } from "vue";
import { useStore } from "vuex";
import CardComponent from "@/components/card/CardComponent.vue";
import AddressUpdateModal from "@/modules/addresses/widgets/AddressUpdateModal.vue";
import AddressCreateModal from "@/modules/addresses/widgets/AddressCreateModal.vue";
import AddressDeleteModal from "@/modules/addresses/widgets/AddressDeleteModal.vue";
import ApiService from "@/core/services/ApiService";
import Notify from "@/modules/common/helpers/Notify";
import { useRoute } from "vue-router";
import { AddressInterface } from "@/modules/addresses/interfaces/Address.interface";

interface ComponentData {
  deletableAddress: AddressInterface | null;
  editableAddress: AddressInterface | null;
  isCreateAddress: boolean;
  addresses;
}

export default defineComponent({
  name: "AddressesCard",
  components: {
    AddressCreateModal,
    AddressDeleteModal,
    AddressUpdateModal,
    CardComponent,
  },

  setup() {
    const route = useRoute();
    const state = reactive<ComponentData>({
      deletableAddress: null,
      editableAddress: null,
      addresses: null,
      isCreateAddress: false,
    });

    const roles = computed(() => {
      return useStore().getters.currentUser.roles;
    });

    const setEditAddress = (address): void => {
      state.editableAddress = address;
    };

    const setDeleteAddress = (address): void => {
      state.deletableAddress = address;
    };

    const reloadAddresses = (): void => {
      loadAddresses();
    };

    const loadAddresses = (): void => {
      ApiService.get(`customer/` + route.params.id + `/addresses`)
        .then(({ data }) => {
          state.addresses = data.addresses;
        })
        .catch((error) => Notify.apiError(error))
        .finally();
    };

    onMounted(() => {
      loadAddresses();
    });

    return {
      state,
      roles,
      setEditAddress,
      setDeleteAddress,
      loadAddresses,
      reloadAddresses,
    };
  },
});
</script>

<style scoped></style>
