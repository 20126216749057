<template>
  <card-component>
    <template #header>
      <i class="fas fa-envelope card-header-icon"></i>
      Emails
      <span class="badge badge-secondary m-2">{{ emails.length }}</span>
    </template>
    <template #default>
      <div v-if="loading" class="text-center">
        <loading-indicator />
      </div>
      <request-failed-error
        v-if="error"
        :message="error"
        @refresh="getEmails"
      />
      <div v-if="emails != null">
        <div id="emails_container" class="card_father scroll">
          <table class="table table-striped">
            <thead>
              <tr
                class="fw-bold fs-6 text-gray-800 border-bottom border-gray-200"
              >
                <th>Subject</th>
                <th>Created at</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="e in emails" :key="e.id" class="email_letter">
                <td>{{ e.subject }}</td>
                <td>{{ e.created_at }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </template>
  </card-component>
</template>

<script lang="ts">
import ApiService from "@/core/services/ApiService";
import { defineComponent } from "vue";
import CardComponent from "@/components/card/CardComponent.vue";
import LoadingIndicator from "@/components/spinners/LoadingIndicator.vue";
import RequestFailedError from "@/components/errors/RequestFailedError.vue";

interface ComponentData {
  loading: boolean;
  error?: unknown;
  emails?: unknown;
}

export default defineComponent({
  name: "EmailList",
  components: { RequestFailedError, LoadingIndicator, CardComponent },
  props: {
    email: {
      type: String,
      required: true,
    },
  },

  data(): ComponentData {
    return {
      loading: true,
      error: undefined,
      emails: {},
    };
  },

  watch: {
    email() {
      this.getEmails();
    },
  },

  mounted() {
    this.getEmails();
  },

  methods: {
    getEmails() {
      this.resetState();
      ApiService.get(`customer/${this.$route.params.id}/emails`)
        .then(({ data }) => {
          this.emails = data.data;
        })
        .catch((error) => {
          this.error = error;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    resetState(): void {
      this.loading = true;
      this.error = undefined;
      this.emails = {};
    },
  },
});
</script>

<style scoped></style>
