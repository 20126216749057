<template>
  <div v-if="state.response">
    <div class="row">
      <div class="col-md-6 mb-2">
        <div class="card card-flush shadow-sm">
          <div class="card-header">
            <h3 class="card-title">
              <i class="fas fa-user card-header-icon"></i>
              {{ state.response.customer.first_name }}
              {{ state.response.customer.last_name }} [{{
                state.response.customer.id
              }}]
            </h3>
            <div class="card-toolbar">
              <button
                type="button"
                class="btn btn-primary btn-sm"
                data-bs-toggle="modal"
                data-bs-target="#customer_update_modal"
                style="margin-right: 0.5rem"
                @click="state.isEditCustomer = true"
              >
                <i class="fas fa-pen"></i>
                Update customer
              </button>
              <button
                type="button"
                class="btn btn-danger btn-sm delete_btn"
                data-bs-toggle="modal"
                data-bs-target="#delete_modal"
              >
                <i class="fas fa-trash"></i>
              </button>
            </div>
          </div>
          <div class="card-body bg-white">
            <div>
              Name: <b>{{ state.response.customer.first_name }}</b>
            </div>
            <div>
              Surname: <b>{{ state.response.customer.last_name }}</b>
            </div>
            <div>
              Email: <b>{{ state.response.customer.email }}</b>
            </div>
            <div>
              Registered:
              <span v-if="state.response.customer.is_guest === false"
                >Yes <i class="fas fa-check text-success"></i
              ></span>
              <span v-if="state.response.customer.is_guest === true"
                >No <i class="fas fa-times text-danger"></i
              ></span>
            </div>
            <div>
              Created at:
              {{ state.response.customer.created_at }}
            </div>
            <div>
              Browser country: {{ state.response.customer.browser_country }}
            </div>
            <div>Language: {{ state.response.customer.language }}</div>
            <div>Group: {{ state.response.customer.group }}</div>
            <div>Total orders: {{ state.response.customer.total_orders }}</div>
          </div>
        </div>

        <previous-orders-widget
          :customer-id="state.response.customer.id"
          header-name="Orders"
        />
        <carts-card :carts="state.response.carts" />
      </div>

      <div class="col-md-6 mb-3">
        <traces-card :traces="state.response.traces" />
        <email-card :email="state.response.customer.email" />
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 mb-3">
        <addresses-card />
      </div>
    </div>

    <CustomerUpdateModal
      v-if="state.isEditCustomer === true"
      :customer="state.response.customer"
      @updated="customerUpdated()"
      @hide="() => (state.isEditCustomer = false)"
    />
    <CustomerDeleteModal :customer="state.response.customer" />
  </div>
</template>

<script lang="ts">
import ApiService from "@/core/services/ApiService";
import EmailCard from "@/modules/customers/widgets/EmailCard.vue";
import TracesCard from "@/modules/customers/widgets/TracesCard.vue";
import AddressesCard from "@/modules/customers/widgets/AddressesCard.vue";
import CartsCard from "@/modules/customers/widgets/CartsCard.vue";
import { defineComponent, reactive } from "vue";
import PreviousOrdersWidget from "@/modules/common/widgets/PreviousOrders/PreviousOrdersWidget.vue";
import CustomerUpdateModal from "@/modules/customers/widgets/CustomerUpdateModal.vue";
import CustomerDeleteModal from "@/modules/customers/widgets/CustomerDeleteModal.vue";
import Notify from "@/modules/common/helpers/Notify";
import { CustomerResponse } from "@/modules/customers/interfaces/CustomerResponse.interface";
import { useRoute } from "vue-router";
import { setPageTitle } from "@/core/helpers/dom";

interface ComponentData {
  response?: CustomerResponse | null;
  submitButtonLoading: boolean;
  isEditCustomer: boolean;
}

export default defineComponent({
  name: "ResourceViewPage",
  components: {
    PreviousOrdersWidget,
    AddressesCard,
    EmailCard,
    TracesCard,
    CartsCard,
    CustomerUpdateModal,
    CustomerDeleteModal,
  },

  setup() {
    const route = useRoute();
    const state = reactive<ComponentData>({
      response: null,
      submitButtonLoading: false,
      isEditCustomer: false,
    });

    const customerUpdated = (): void => {
      getCustomer();
    };

    setPageTitle("Customer #" + String(route.params.id));

    const getCustomer = (): void => {
      ApiService.get(`customer/` + String(route.params.id))
        .then(({ data }: { data: CustomerResponse }) => {
          console.log(data);
          state.response = data;
        })
        .catch((error) => Notify.apiError(error))
        .finally();
    };
    getCustomer();

    return {
      state,
      customerUpdated,
      getCustomer,
    };
  },
});
</script>

<style>
.card {
  margin-bottom: 1.5rem;
}

.card_father {
  max-height: 200px;
}

.card-header-icon {
  font-size: 1.5rem;
  margin: 0 1rem 0 0;
}

.card-header {
  min-height: 4rem !important;
  padding: 0 1.5rem !important;
}

.card-body {
  padding: 0.5rem 1.5rem 1.5rem 1.5rem !important;
}

td {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

table {
  margin-bottom: 0;
}

.delete_btn i {
  padding: 0;
}
</style>
