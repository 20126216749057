
import { defineComponent } from "vue";
import CardComponent from "@/components/card/CardComponent.vue";

export default defineComponent({
  name: "TracesList",
  components: { CardComponent },
  props: {
    traces: {
      type: Array,
      required: true,
    },
  },
});
