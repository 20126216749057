<template>
  <modal-component v-if="cart" id="cart-view-modal">
    <template #title> Cart # {{ cart.id }}</template>
    <template #default>
      <table class="table table-striped">
        <thead>
          <tr class="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
            <th></th>
            <th>Name</th>
            <th>Variation ID</th>
            <th>Reference</th>
            <th>Size</th>
            <th>Price</th>
            <th>Quantity</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in state.items"
            :key="item.id"
            class="cart_item align-middle"
          >
            <td>
              <img
                v-if="item.image_url != null"
                :src="item.image_url"
                :alt="item.name"
                width="80"
              />
            </td>
            <td>{{ item.name }}</td>
            <td>{{ item.variation_id }}</td>
            <td>{{ item.reference }}</td>
            <td>{{ item.size }}</td>
            <td>€ {{ item.price }}</td>
            <td>{{ item.quantity }}</td>
          </tr>
        </tbody>
      </table>
      <div class="row">
        <div class="col">
          <table>
            <tr>
              <th style="padding-right: 10px">Total price</th>
              <td>€ {{ cart.total_sum }} with {{ cart.total_items }} items</td>
            </tr>
            <tr>
              <th style="padding-right: 10px">Shipping type</th>
              <td>{{ cart.carrier }}</td>
            </tr>
            <tr>
              <th style="padding-right: 10px">Created at</th>
              <td>{{ cart.created_at }}</td>
            </tr>
          </table>
        </div>
      </div>
    </template>
  </modal-component>
</template>

<script lang="ts">
import { defineComponent, onMounted, PropType, reactive } from "vue";
import ModalComponent from "@/components/modals/ModalComponent.vue";
import { AddressInterface } from "@/modules/addresses/interfaces/Address.interface";
import ApiService from "@/core/services/ApiService";
import Notify from "@/modules/common/helpers/Notify";

interface CartItem {
  image_url: string;
  name: string;
  variation_id: number;
  reference: string;
  size: string;
  price: number;
  quantity: number;
}

interface ComponentData {
  items: CartItem[] | null;
}

export default defineComponent({
  name: "CartViewModal",
  components: {
    ModalComponent,
  },

  props: {
    cart: {
      type: Object as PropType<AddressInterface>,
      required: true,
    },
  },

  setup(props) {
    const state = reactive<ComponentData>({
      items: null,
    });

    const loadCartItems = (): void => {
      ApiService.get(`cart/` + props.cart.id)
        .then(({ data }) => {
          state.items = data.items;
        })
        .catch((error) => Notify.apiError(error))
        .finally();
    };
    onMounted(() => {
      loadCartItems();
    });

    return {
      state,
      props,
      loadCartItems,
    };
  },
});
</script>

<style scoped></style>
