
import { defineComponent, reactive, computed, onMounted } from "vue";
import { useStore } from "vuex";
import CardComponent from "@/components/card/CardComponent.vue";
import AddressUpdateModal from "@/modules/addresses/widgets/AddressUpdateModal.vue";
import AddressCreateModal from "@/modules/addresses/widgets/AddressCreateModal.vue";
import AddressDeleteModal from "@/modules/addresses/widgets/AddressDeleteModal.vue";
import ApiService from "@/core/services/ApiService";
import Notify from "@/modules/common/helpers/Notify";
import { useRoute } from "vue-router";
import { AddressInterface } from "@/modules/addresses/interfaces/Address.interface";

interface ComponentData {
  deletableAddress: AddressInterface | null;
  editableAddress: AddressInterface | null;
  isCreateAddress: boolean;
  addresses;
}

export default defineComponent({
  name: "AddressesCard",
  components: {
    AddressCreateModal,
    AddressDeleteModal,
    AddressUpdateModal,
    CardComponent,
  },

  setup() {
    const route = useRoute();
    const state = reactive<ComponentData>({
      deletableAddress: null,
      editableAddress: null,
      addresses: null,
      isCreateAddress: false,
    });

    const roles = computed(() => {
      return useStore().getters.currentUser.roles;
    });

    const setEditAddress = (address): void => {
      state.editableAddress = address;
    };

    const setDeleteAddress = (address): void => {
      state.deletableAddress = address;
    };

    const reloadAddresses = (): void => {
      loadAddresses();
    };

    const loadAddresses = (): void => {
      ApiService.get(`customer/` + route.params.id + `/addresses`)
        .then(({ data }) => {
          state.addresses = data.addresses;
        })
        .catch((error) => Notify.apiError(error))
        .finally();
    };

    onMounted(() => {
      loadAddresses();
    });

    return {
      state,
      roles,
      setEditAddress,
      setDeleteAddress,
      loadAddresses,
      reloadAddresses,
    };
  },
});
