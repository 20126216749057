
import { defineComponent } from "vue";
import ApiService from "@/core/services/ApiService";
import Notify from "@/modules/common/helpers/Notify";
import { ElMessage } from "element-plus";

export default defineComponent({
  name: "CustomerDeleteModal",

  props: {
    customer: {
      type: Object,
      required: true,
    },
  },

  methods: {
    deleteCustomer: function () {
      ApiService.delete(`customer/${this.$route.params.id}`)
        .then(() => {
          ElMessage.success("Customer deleted.");
        })
        .catch((error) => Notify.apiError(error));
    },
  },
});
